<template>
  <b-container fluid="md" :class="[isMobile ? 'mt-5 pt-3 mb-5 pb-4' : 'mt-3' ]">
      <b-alert v-model="showScopeTextAlert" variant="danger" dismissible>
         The contract scope text can not be blank!
      </b-alert>
      <b-card no-body>
            <b-tabs card v-model="tabIndex">
                <b-tab title="1. Customer" active>
                    <b-card-text>
                        <editor-customer 
                            :nameErrorState="nameErrorState" 
                            :jobTypeState="jobTypeState" 
                            :marketingState="marketingState"
                            :billingAddressState="billingAddressState"
                            :siteAddressState="siteAddressState">
                        </editor-customer>
                    </b-card-text>
                    <b-card-footer class="d-flex flex-row-reverse">
                        <b-button variant="primary" @click="goToTab(1)" >Next <font-awesome-icon icon="chevron-circle-right"/></b-button>
                    </b-card-footer>
                </b-tab>
                <b-tab title="2. Scope">
                    
                    <editor-scope :editing="false" />
                    
                    <b-card-footer  class="d-flex flex-row-reverse">
                        <b-button variant="primary" @click="goToTab(2)" class="ml-1">Next <font-awesome-icon icon="chevron-circle-right"/></b-button>
                        <b-button @click="goToTab(0)" ><font-awesome-icon icon="chevron-circle-left"/> Previous</b-button>
                    </b-card-footer>
        
                </b-tab>
                <b-tab title="3. Money" >
                    <editor-money />
                    <b-card-footer class="d-flex flex-row-reverse">
                        <b-button variant="success" @click.prevent="submitNewJob()" class="ml-1">Submit <font-awesome-icon icon="check" /></b-button>
                        <b-button @click="goToTab(1)" ><font-awesome-icon icon="chevron-circle-left" /> Previous</b-button>
                    </b-card-footer>
                </b-tab>
            </b-tabs>
        </b-card>
  </b-container>

</template>

<script>
import editorCustomer from '../components/contractEditor-components/editorCustomer.vue'
import editorScope from '../components/contractEditor-components/editorScope.vue'
import editorMoney from '../components/contractEditor-components/editorMoney.vue'
import { parse } from 'date-fns'
import { getUnixTime } from 'date-fns'
import numbro from "numbro"


export default {
  name: "contractEditor",
  components: {
      editorCustomer,
      editorScope,
      editorMoney
  },
  data() {
    return {
        ready: false,
        tabIndex: 0,
        nameErrorState: null,
        jobTypeState: null,
        marketingState: null,
        siteAddressState: null,
        billingAddressState: null,
        showScopeTextAlert: false
    }
  },
  computed: {
      contract() {
          return this.$state.newContract.contract
      },
      customer() {
          return this.$state.newContract.customer
      },
      company() {
          return this.$state.userInfo.company
      },
      userInfo () {
        return this.$state.userInfo
      },
      isMobile () {
        return this.$state.isMobile
      }
  },
  methods: {
    goToTab(tabNumber) {
        if(tabNumber > this.tabIndex) {
            if(tabNumber == 1){
                //validation Checks
                if(!this.customer.lname && !this.customer.fname) {
                    this.nameErrorState = false
                } else if(!this.contract.jobType) {
                    this.jobTypeState = false
                } else if(!this.contract.marketing) {
                    this.marketingState = false
                } else if(!this.contract.address.street || !this.contract.address.city) {
                    this.siteAddressState = false
                } else if(!this.customer.address.street || !this.customer.address.city) {
                    this.billingAddressState = false
                } else {
                    this.siteAddressState = null
                    this.billingAddressState = null
                    this.marketingState = null
                    this.jobTypeState = null
                    this.nameErrorState = null
                    this.tabIndex = tabNumber
                }
            } else if(tabNumber == 2) {
                //validate that scope isnt blank
                if(!this.contract.scopeText){
                    this.showScopeTextAlert = true
                } else if(this.contract.scopeText == "<p></p>"){
                    this.showScopeTextAlert = true
                } else { 
                    this.showScopeTextAlert = false
                    this.tabIndex = tabNumber
                }
            }
        } else {
            this.tabIndex = tabNumber
        }
        
    },
    async submitNewJob() {
        console.log("Submit New Job was clicked. Gathering Info...")

        let newJob = {
            customer : {
                fname: this.customer.fname,
                lname: this.customer.lname,
                address: {
                    street: this.customer.address.street,
                    city: this.customer.address.city,
                    state: this.customer.address.state,
                    zip: this.customer.address.zip,
                },
                emails: {
                    primary: this.customer.emails.primary,
                    secondary: this.customer.emails.secondary
                },
                phones: {
                    mobile: this.customer.phones.mobile,
                    work: this.customer.phones.work,
                    home: this.customer.phones.home
                },
                company: this.company._id
            },
            contract: {
                siteAddress: {
                    street: this.contract.address.street,
                    city: this.contract.address.city,
                    state: this.contract.address.state,
                    zip: this.contract.address.zip,
                },
                dates: {
                    contractDate: getUnixTime(parse(this.contract.dates.contractDate, 'MM/dd/yyyy', new Date()))                
                },
                jobType: this.contract.jobType,
                location: {
                    lat: this.contract.location.lat,
                    lon: this.contract.location.lon
                },
                marketing: this.contract.marketing,
                salesman: this.contract.salesman,
                scopeText: this.contract.scopeText,
                status: "pending",
                total: numbro.unformat(this.contract.money.total),
                deposit: numbro.unformat(this.contract.money.deposit),
                progressPayment: numbro.unformat(this.contract.money.progressPayment),
                jobLabel: this.contract.jobLabel,
                permitTown: this.contract.permitTown
            },
            money: {
                gutters: numbro.unformat(this.contract.money.Gutters),
                permits: numbro.unformat(this.contract.money.Permits),
                roofing: numbro.unformat(this.contract.money.Roofing),
                siding: numbro.unformat(this.contract.money.Siding),
                windows: numbro.unformat(this.contract.money.Windows),
                balance: numbro.unformat(this.contract.money.balance),
                deposit: numbro.unformat(this.contract.money.deposit),
                progressPayment: numbro.unformat(this.contract.money.progressPayment),
                salesTax: numbro.unformat(this.contract.money.salesTax),
                total: numbro.unformat(this.contract.money.total)
            }
            
        }

        //console.log(newJob)

        //is there a customerID? if not first create new customer...
        if(!this.customer._id) {
            console.log(`No customer._id found, creating new customer.`)
            const newCustomerId = await this.createCustomer(newJob.customer)
            newJob.contract.customer = newCustomerId
            console.log(`New customer created with ID: ${newCustomerId}`)
        } else {
            newJob.contract.customer = this.customer._id
            //console.log(`Customer exists.... Moving On...`)
        }

        console.log(`Calling createContract()...with customerID of: ${newJob.contract.customer}`)
        //now we have a customerId, create contract and money items
        const newContractId = await this.createContract(newJob.contract)
        console.log(`New Job Created!! New Job Id is: ${newContractId}`)
        
        //now we have the contract._id use it to update Money for this job.
        newJob.money.job = newContractId
        let newMoneyId = await this.createMoneyItems(newJob.money)
        console.log(`Congrats!... Money Item Created with id: ${newMoneyId}`)
        
        //log it
        const newLog = {
            logTime: getUnixTime(new Date()),
            logMsg: `New Job created for ${newJob.customer.lname}, ${newJob.customer.fname}`,
            job: newJob.money.job,
            logUser: this.userInfo.name
        }

        this.$actions.log(newLog)
    
        
        this.$root.$bvToast.toast(`New job for ${this.customer.lname} saved successfully.`, {
            title: 'Success',
            variant: 'success',
            toaster: 'b-toaster-top-full'
        })
        
        this.$router.push({ name: 'dashboard' })
        
    },
    logIt(log){
        this.$axios.post("/log", log)
    },
    async createMoneyItems(money) {
        let newMoney = {
            job: money.job,
            description: `${this.contract.jobType} as per contract.`,
            amount: money.total,
            debit: "debit",
            sales: {
                roofing: money.roofing,
                siding: money.siding,
                windows: money.windows,
                gutters: money.gutters,
                tax: money.salesTax,
                permits: money.permits
            },
            transtime: getUnixTime(new Date())
        }

        try {
            console.log("Create Money item...")
            const res = await this.$axios.post("/money", newMoney)
            return res.data
        } catch (err) {
            console.error(err)
        }
    },
    async createCustomer(customer) {
        try {
            console.log("Create customer called...")
            const res = await this.$axios.post("/customer", customer)
            return res.data
        } catch (err) {
            console.error(err)
        }
    },
    async createContract(contract) {
        try {
            console.log("Creating new Contract...")
            const res = await this.$axios.post("/job", contract)
            return res.data
        } catch (err) {
            console.error(err)
        }
    }

  },
  created () {
        
        if(this.$route.params.id) {
            console.log(`id is available. it is: ${this.$route.params.id}`)
            this.contract._id = this.$route.params.id
        }
        
  }
}
</script>


<style lang="scss" scoped>
   
</style>
