<template>
  <div class="autocomplete">
    <b-form-input :state="errorState" v-model="search" @input="onChange()" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" @keydown.tab="onTab" trim autocomplete="off">
    </b-form-input>
    <ul class="autocomplete-results" v-show="isOpen">
      <li v-if="isLoading" class="loading">
        Loading results...
      </li>
      <li v-else class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }" v-for="(result, i) in results" :key="i" @click="setResult(result)">
          {{result.customer.lname}}, {{result.customer.fname}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'autoComplete',
  props: {
      errorState: Boolean,
      items: {
          type: Array,
          required: false,
          default: () => []
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      }
  },
  watch: {
      items: function (value) {
          if(this.isAsync){
            this.results = value;
            this.isOpen = true;
            this.isLoading = false;
          }
      }
  },
  data() {
      return {
          search: '',
          isOpen: false,
          results: [],
          arrowCounter: -1,
          isLoading: false
      }
  },
  methods: {
    filterResults() {
        this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    onChange() {
        if(this.search.length > 2) {
            this.$emit('input', this.search)
            if(this.isAsync){
                this.isLoading = true
            } else {
                this.filterResults()
                this.isOpen = true
            }

        }
    },
    setResult(result) {
        this.search = result.customer.lname
        this.isOpen = false
        //send result back to parent
        this.$emit('itemselected', result)
    },
    handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
            this.arrowCounter = -1
            this.isOpen = false
            this.isLoading = false
            this.$state.newContract.customer.lname = this.search
        }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onTab() {
        if (this.arrowCounter == -1) {
            this.isLoading = false
            this.isOpen = false
            this.$state.newContract.customer.lname = this.search
        }
    },
    onEnter() {
        if(this.isAsync) {
            this.$emit('itemselected', this.results[this.arrowCounter])
            this.search = this.results[this.arrowCounter].customer.lname
            this.arrowCounter = -1
            this.isOpen = false
        } else {
            this.search = this.results[this.arrowCounter]
            this.arrowCounter = -1
            this.isOpen = false
        }
      
    }
  },
  mounted() {
      document.addEventListener('click', this.handleClickOutside)
  },
  destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
  }
};
</script>

<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    position: absolute;
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 250px;
    width: 100%;
    min-height: .5em;
    max-height: 13em;
    overflow: auto;
  }

  .loading,
  .autocomplete-result {
    background-color: white;
    border-bottom: 1px solid #eee;
    list-style: none;
    text-align: left;
    padding: 8px 6px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>