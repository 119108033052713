<template>
    <b-container>
        <b-row>
            <b-col sm>
                <h5>Contact Information</h5>
                <b-form-group id="grpLname" description="Last Name" v-show="$route.params.id">
                    <b-form-input id="lname" v-model="customer.lname" trim autocomplete="off" :state="nameErrorState"></b-form-input>
                </b-form-group>
                <b-form-group id="grpLname" description="Last Name" v-if="!$route.params.id">
                    <auto-complete ref="lnametxt" :errorState="nameErrorState" :items="searchResults" @input="handleInput" @itemselected="itemselected" :is-async="true"></auto-complete>
                </b-form-group>
                <b-form-group id="grpFname" description="First Name" >
                    <b-form-input id="fname" v-model="customer.fname" trim autocomplete="off" :state="nameErrorState"></b-form-input>
                </b-form-group>
                <b-form-group id="grpMphone" description="Mobile Phone" >
                    <b-form-input id="mphone" v-model="customer.phones.mobile" trim autocomplete="off"></b-form-input>
                </b-form-group>
                <b-form-group id="grpHphone" description="Home Phone" >
                    <b-form-input id="hphone" v-model="customer.phones.home" trim autocomplete="off"></b-form-input>
                </b-form-group>
                <b-form-group id="grpWphone" description="Work Phone" >
                    <b-form-input id="wphone" v-model="customer.phones.work" trim autocomplete="off"></b-form-input>
                </b-form-group>
                <b-form-group id="grpEmail1" description="Email" >
                    <b-form-input id="email1" v-model="customer.emails.primary" trim autocomplete="off"></b-form-input>
                </b-form-group>
                <b-form-group id="grpEmail2" description="Email 2" >
                    <b-form-input id="email2" v-model="customer.emails.secondary" trim autocomplete="off"></b-form-input>
                </b-form-group>
         
            </b-col>
            <b-col sm>
                <h5>Billing Address</h5>
                <b-form-group id="grpBstreet" description="Street" >
                    <b-form-input id="bstreet" v-model="customer.address.street" trim autocomplete="off" :state="billingAddressState"></b-form-input>
                </b-form-group>
                <b-form-group id="grpBcity" description="City" >
                    <b-form-input ref="bcity" v-model="customer.address.city" @blur="lookupZip('billing')" trim :state="billingAddressState">></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col cols="6">
                        <b-form-group id="grpBstate" description="State" >
                            <b-form-input ref="bstate" v-model="customer.address.state" trim autocomplete="off"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group id="grpBzip" description="Zip Code" >
                            <b-form-input ref="bzip" v-model="customer.address.zip" trim autocomplete="off"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <br />
                <h5>Jobsite Address</h5>
                <b-form-group id="grpSstreet" description="Street" >
                    <b-input-group>
                        <b-form-input id="sstreet" ref="siteaddress" v-model="contract.address.street" trim autocomplete="off" :state="siteAddressState"></b-form-input>
                        <b-input-group-append>
                            <b-button ref="copyAddressBtn" variant="outline-primary" @click="copyAddress()"> Copy <font-awesome-icon icon="copy" /></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group id="grpScity" description="City" >
                    <b-form-input ref="scity" v-model="contract.address.city" @blur="lookupZip('site')" trim autocomplete="off" :state="siteAddressState"></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col cols="6">
                        <b-form-group id="grpSstate" description="State" >
                            <b-form-input ref="sstate" v-model="contract.address.state" trim autocomplete="off"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group id="grpSzip" description="Zip Code" >
                            <b-form-input ref="szip" v-model="contract.address.zip" trim autocomplete="off"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>
        
        <b-row>
            <b-col>
                <h5>Contract Info</h5>
            </b-col>
            
        </b-row>
            
        <b-row sm>
            <b-col sm>    
                <b-row>
                    <b-col cols="6">
                        <b-form-group id="grpContractDate" description="Contract Date" >
                            <b-form-input ref="contractDate" v-model="contract.dates.contractDate" trim autocomplete="off"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group id="grpSalesman" description="SalesMan" >
                            <b-form-select ref="salesman" v-model="contract.salesman" :options="salesmanOptions" trim></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group id="grpJobLabel" description="Job Label/Short desc" >
                    <b-form-input ref="jobLabel" v-model="contract.jobLabel" trim autocomplete="off"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm>
                <b-row>
                    <b-col cols="6">
                        <b-form-group id="grpJobType" description="Job Type" >
                            <b-form-select ref="jobType" v-model="contract.jobType" :options="jobTypeOptions" :state="jobTypeState" trim></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group id="grpMarketing" description="Marketing" >
                            <b-form-select ref="marketing" v-model="contract.marketing" :options="marketingOptions" :state="marketingState" trim></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group description="Job Requires Permit?" >
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-form-checkbox switch class="mr-n2" v-model="permitSwitch" :value="true" :unchecked-value="false">
                                <span class="sr-only">Switch to enable Permit</span>
                            </b-form-checkbox>
                        </b-input-group-prepend>
                        <b-form-input ref="permitTown" v-model="contract.permitTown" trim :disabled="!permitSwitch" :placeholder="permitSwitch ? 'Enter Permit Township' : ''"></b-form-input> 
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import autoComplete from '@/components/autoComplete.vue'

import "animate.css"

//import gmapsInit from '@/assets/gmapConfig.js'
// gMaps api key  !! Restrict this key in the future - korey
// AIzaSyDZbo8X_q8crHijPzXsjD6krj2luf-00Jw

import { format } from 'date-fns'
import { fromUnixTime } from 'date-fns'

export default {
  name: "editorCustomer",
  components: {
      autoComplete
  },
  props: {
      nameErrorState: Boolean,
      jobTypeState: Boolean,
      marketingState: Boolean,
      billingAddressState: Boolean,
      siteAddressState: Boolean
  },
  computed: {
    contract() {
        return this.$state.newContract.contract
    },
    customer() {
        return this.$state.newContract.customer
    },
    id () {
        return this.$route.params._id
    },
    userInfo () {
        return this.$state.userInfo
    }
  },
  data() {
    return {
        //format: format,
        //fromUnixTime: fromUnixTime,
        searchResults: [],
        permitSwitch: false,
        salesmanOptions: [],
        jobTypeOptions: [],
        marketingOptions: [
            { text: "Choose...", value: null },
            { text: "Referral", value: "Referral" },
            { text: "Phone Book", value: "Phone Book" },
            { text: "Repeat Customer", value: "Repeat Customer" },   
            { text: "Job-Site Signs", value: "Job-Site Signs" },    
            { text: "Internet", value: "Internet" }, 
            { text: "Other", value: "Other" },          
        ]
    }
  },
  mounted() {
    /*
      this.$nextTick(() => {
          this.$refs.lnametxt.$el.focus()
      })
    */
  },
  created() {
      this.contract.dates.contractDate = format(fromUnixTime(Date.now()/1000), "MM/dd/yyyy")
      this.contract.salesman = this.userInfo._id
      this.getCompanyUsers()
      this.getCompanyJobTypes()
      
      if(this.$route.params.id){
          this.getCopyInfo(this.$route.params.id)
      }
  },
  methods: {
    async getCopyInfo(id) {
        let res = await this.$axios.get(`/customer/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            let result = {}
            result.customer = res.data
            this.itemselected(result)
    },
    itemselected(result) {

          console.log(result)
          //this.customer.customerId = result.customer.customerId
          //
          
          this.customer._id = result.customer._id
          this.customer.fname = result.customer.fname
          this.customer.lname = result.customer.lname
          this.customer.address.street = result.customer.address.street
          this.customer.address.city = result.customer.address.city
          this.customer.address.state = result.customer.address.state
          this.customer.address.zip = result.customer.address.zip
          this.customer.phones.mobile = result.customer.phones.mobile
          this.customer.phones.home = result.customer.phones.home
          this.customer.phones.work = result.customer.phones.work
          this.customer.emails.primary = result.customer.emails.primary
          this.customer.emails.secondary = result.customer.emails.secondary
          

          this.$refs.siteaddress.focus()

    },
    handleInput(input) {
        console.log(`Input detected: ${input}`)
        this.searchNames(input)
    },
    async searchNames(input){
        try {
            console.log("calling name search...")
            let res = await this.$axios.get(`/customer/search/name/${input}`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            console.log(res.data)
            this.searchResults = res.data

        } catch (err) {
            console.error(err)
        }
    },
    async getCompanyJobTypes() {
        let jobTypeOptions = [{text: "Choose...", value: null}]
        this.userInfo.company.jobTypes.forEach(type => {
            jobTypeOptions.push({ text: type, value: type })
        })
        this.jobTypeOptions = jobTypeOptions
    },
    async getCompanyUsers() {
        //console.log("calling getCompanyUsers")
        try {
            let res = await this.$axios.get(`/user/`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            //console.log("Users:")
            //console.log(res.data)
            let salesmanOptions = []
            
            res.data.forEach(user => {
                if(user.status.toLowerCase() === "active" ) {
                    salesmanOptions.push({ value: user._id, text: user.name })
                }       
            })
            
            this.salesmanOptions = salesmanOptions

        } catch (err) {
            console.error(err)
        }
      
     },
    async lookupZip(loc) {
        console.log("calling lookup Zip")
        //geoc
          let apiKey = "101d562b2155dee5b664021b1042d15e5b4bb01"
          let city = ""
          let street = ""
          let state = ""

          if(loc == 'site') {
              city = this.contract.address.city
              street = this.contract.address.street
              state = this.contract.address.state
          } else {
              city = this.customer.address.city
              street = this.customer.address.street
              state = this.customer.address.state
          }
          
          try {
                
                let res = await this.$axios.get(`https://api.geocod.io/v1.6/geocode?q=${encodeURI(`${street}, ${city}, ${state}`)}&country=us&api_key=${apiKey}`, {
                    headers: {}
                })

                //console.log(res.data)

                 
                let zipCode = res.data.results[0].address_components.zip

                //console.log(`The Zip code is: ${zipCode}`)
                
                
                
                if(loc == 'site') {
                    this.contract.address.zip = zipCode
                    this.$refs.szip.$el.classList.add('animate__animated', 'animate__tada')
                    this.contract.location.lat = res.data.results[0].location.lat
                    this.contract.location.lon = res.data.results[0].location.lng

                } else {
                    this.customer.address.zip = zipCode
                    this.$refs.bzip.$el.classList.add('animate__animated', 'animate__tada')
                }
                
                
            } catch (error) {
                console.error(error)
            }
          
    },
    copyAddress(){
        this.contract.address.street = this.customer.address.street
        this.contract.address.city = this.customer.address.city
        this.contract.address.state = this.customer.address.state
        this.contract.address.zip  = this.customer.address.zip 
        this.lookupZip('site')
    }
  }
}

</script>


<style lang="scss" scoped>

</style>