<template>
    <b-container>
        <b-row>
            <b-col sm>
                <h5>Sales Breakdown</h5>
                <b-form-group v-for="(cat, index) in company.salesTrackingCatagories" :key="index" :description="cat.val" >
                    <b-input-group prepend="$">
                        <b-form-input :id="cat.val" v-model="contract.money[company.salesTrackingCatagories[index].val]" @blur="addItUp()"></b-form-input>
                    </b-input-group>
                </b-form-group>
                
            </b-col>
            <b-col sm>
                <h5>Contract Amounts</h5>
                <b-form-group :description="`Sales Tax Rate ${this.company.salesTaxRate}%`" >
                    <b-input-group prepend="$">
                        <b-form-input ref="salesTax" v-model="contract.money.salesTax" trim @blur="addItUp()"></b-form-input>
                        <b-input-group-append>
                            <b-button ref="calcTaxBtn" variant="outline-primary" @click="calcTax()">Calc</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group description="Total Sale" >
                    <b-input-group prepend="$">
                        <b-form-input ref="totalSale" v-model="contract.money.total" trim disabled></b-form-input>
                    </b-input-group>
                </b-form-group>
                <b-form-group description="Deposit" >
                    <b-input-group prepend="$">
                        <b-form-input ref="deposit" v-model="contract.money.deposit" trim @blur="customDeposit()"></b-form-input>
                        <b-input-group-append>
                            <b-button ref="halfDepBtn" :variant="halfDepBtnVariant" @click="setDepositRate(.5)">1/2</b-button>
                            <b-button ref="thirdDepBtn" :variant="thirdDepBtnVariant" @click="setDepositRate(.333333334)">1/3</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group description="Progress Payment" >
                    <b-input-group prepend="$">
                        <b-form-input ref="progressPayment" v-model="contract.money.progressPayment" trim :disabled="!progressSwitch" @blur="addItUp()"></b-form-input>
                        <b-input-group-append is-text>
                            <b-form-checkbox switch class="mr-n2" v-model="progressSwitch" :value="true" :unchecked-value="false">
                                <span class="sr-only">Switch to enable progress payment</span>
                            </b-form-checkbox>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group description="Balance" >
                    <b-input-group prepend="$">
                        <b-form-input ref="balance" v-model="contract.money.balance" trim disabled></b-form-input>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import numbro from "numbro"

//import { format } from 'date-fns'
//import { fromUnixTime } from 'date-fns'

export default {
  name: "editorMoney",
  components: {

  },
  computed: {
    ready() {
        if (this.$state.sessionIntiated) {
            return true
        } else {
            return false
        }
    },
    contract() {
        return this.$state.newContract.contract
    },
    customer() {
        return this.$state.newContract.customer
    },
    company() {
        return this.$state.userInfo.company
    }
  },
  data() {
    return {
        numbro: numbro,
        depositRate : .3333334,
        progressSwitch: false,
        halfDepBtnVariant: "outline-primary",
        thirdDepBtnVariant: "primary"
        //format: format,
        //fromUnixTime: fromUnixTime,
    }
  },
  created() {
        
  },
  methods: {
     async addItUp() {
        
        let roofing = numbro.unformat(this.contract.money.Roofing)
        let siding = numbro.unformat(this.contract.money.Siding)
        let gutters = numbro.unformat(this.contract.money.Gutters)
        let windows = numbro.unformat(this.contract.money.Windows)
        let permits = numbro.unformat(this.contract.money.Permits)

        let newTotal = roofing + siding + gutters + windows + permits
        let newTotalWithTax = newTotal + numbro.unformat(this.contract.money.salesTax)
        
        this.contract.money.total = numbro(newTotalWithTax).formatCurrency({thousandSeparated: true, mantissa: 2})
        this.calcDeposit().then(() => {
            this.calcBalance()
            this.prettifyMoney()
        })
        
        return {total: newTotal, totalWithTax: newTotalWithTax}
     },
     prettifyMoney() {
         for (const money in this.contract.money) {
             if(this.contract.money[money] != "0.00") {
                 this.contract.money[money] = numbro(this.contract.money[money]).formatCurrency({thousandSeparated: true, mantissa: 2})
             }
             
         }
     },
     async calcTax() {
         let total = await this.addItUp()
         console.log(`additup Called. total without tax is now ${total.total}`)
         let salesTaxRate = numbro(this.company.salesTaxRate)
         let unformatedTotal = numbro.unformat(total.total)
         let calculatedTax = unformatedTotal * (salesTaxRate/100)
         console.log(`tax calcualted as ${calculatedTax}`)
         this.contract.money.salesTax = numbro(calculatedTax).formatCurrency({thousandSeparated: true, mantissa: 2})
         let newTotal = unformatedTotal + calculatedTax
         this.contract.money.total = numbro(newTotal).formatCurrency({thousandSeparated: true, mantissa: 2})
         this.calcDeposit().then(() => {
             this.calcBalance()
         })
     },
     async calcDeposit() {
         //console.log("calcing Deposit")
         let total = numbro.unformat(this.contract.money.total)
         //console.log(`Calcing Deposit based on total and tax of ${total}`)
         let calcDeposit = numbro(total) * this.depositRate
         let formatedDeposit = numbro(calcDeposit).formatCurrency({thousandSeparated: true, mantissa: 2})
         this.contract.money.deposit = formatedDeposit
         //console.log(`Calculated deposit is: ${formatedDeposit}`)
         return this.contract.money.deposit
     },
     customDeposit() {
         //if custom deposit is entered just refigure balance...
         let newBalance = numbro.unformat(this.contract.money.total) - numbro.unformat(this.contract.money.deposit) - numbro.unformat(this.contract.money.progressPayment)
         this.contract.money.balance = numbro(newBalance).formatCurrency({thousandSeparated: true, mantissa: 2})
         this.contract.money.deposit = numbro(this.contract.money.deposit).formatCurrency({thousandSeparated: true, mantissa: 2})
     },
     setDepositRate(rate) {
         this.depositRate = rate
         this.addItUp()
         if(rate == .5){
             this.halfDepBtnVariant = "primary"
             this.thirdDepBtnVariant = "outline-primary"
         } else {
             this.halfDepBtnVariant = "outline-primary"
             this.thirdDepBtnVariant = "primary"
         }
     },
     async calcBalance() {
         if(this.contract.money.progressPayment != "0.00") {
             let balance = numbro.unformat(this.contract.money.total) - numbro.unformat(this.contract.money.deposit) - numbro.unformat(this.contract.money.progressPayment)
             this.contract.money.balance = numbro(balance).formatCurrency({thousandSeparated: true, mantissa: 2})
         } else {
             let balance = numbro.unformat(this.contract.money.total) - numbro.unformat(this.contract.money.deposit)
             this.contract.money.balance = numbro(balance).formatCurrency({thousandSeparated: true, mantissa: 2})
         }
     },
     enableProgressPayment() {
         console.log(`Progress Payemnt Enabled`)
     }
  }
}

</script>


<style lang="scss" scoped>

</style>